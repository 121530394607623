import "./contact.scss";

const form = document.querySelector<HTMLFormElement>("#form");
const button = form.querySelector<HTMLButtonElement>(".button");
const success = form.querySelector<HTMLElement>(".form__success");
const error = form.querySelector<HTMLElement>(".form__error");

const setIsLoading = (isLoading: boolean) => {
  if (isLoading) {
    button.classList.add("button--loading");
  } else {
    button.classList.remove("button--loading");
  }
};

const setIsSuccess = (isSuccess: boolean) => {
  success.hidden = !isSuccess;
};

const setIsError = (isError: boolean) => {
  error.hidden = !isError;
};

const TELEGRAM_TOKEN = "5834276042:AAE4g6XGLkrJ7Y3aHcE_ULDAAZck-zmu3WI";
const apiUrl = "https://api.telegram.org/bot";

form.addEventListener("submit", function (event) {
  event.preventDefault();

  setIsLoading(true);

  const formData = new FormData(form);
  const { name, email, message } = Object.fromEntries(formData.entries());

  const body = {
    chat_id: "-1001802356017",
    parse_mode: "HTML",
    text: `<b>New request for the demo</b>\nName: <b>${name}</b>\nEmail: ${email}\nMessage: ${
      message || "—"
    }`,
  };

  fetch(`${apiUrl}${TELEGRAM_TOKEN}/sendMessage`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  })
    .then((response) => response.json())
    .then(({ ok, error_code, description }) => {
      if (!ok) {
        throw new Error(`${error_code}: ${description}`);
      }

      setIsSuccess(true);
      setIsError(false);
    })
    .catch((error) => {
      console.error(error);
      setIsSuccess(false);
      setIsError(true);
    })
    .finally(() => {
      setIsLoading(false);
    });
});
